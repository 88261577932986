import React, { useRef, useState } from "react"
import { useEffect } from "react"
import { styled } from "styled-components"
import {Menu as DesktopMenu} from 'antd';
import {NavBar as MobileMenu} from 'antd-mobile';
import Typography from "antd/es/typography";
import {AiOutlineTwitter, AiFillInstagram, AiFillLinkedin, AiOutlineFacebook, AiFillPhone, AiFillMail} from 'react-icons/ai'
import {RiTiktokFill} from 'react-icons/ri'
import {BsYelp} from 'react-icons/bs'
import {BiLogoFacebook, BiLogoLinkedin} from 'react-icons/bi'
import { useNavigate } from "react-router-dom";

type NavbarProps = React.PropsWithChildren<{}>
function usePreviousValue(value: string) {
    const ref = useRef<string>(value);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
export const Navbar = (props: NavbarProps) => {
    const [selected, setSelected] = useState<string>('home');
    const previousSelected = usePreviousValue(selected);
    const navigate = useNavigate();
    useEffect(() => {
        if (selected === "phone") {
            return;
        }
        if (selected === "yelp") {
            return;
        }
        navigate(selected);
    }, [selected])
    return (
        <Container>
            <CompanyName>Macvac</CompanyName>
            <MenuContainer>
                <StyledDesktopMenu defaultValue="home" selectedKeys={[selected]} onClick={e=>setSelected(e.key)}  style={{borderBottom: 'none'}} mode="horizontal" items={[
                    {
                        label: 'Home',
                        key: 'home'
                    },
                    {
                        label: 'About Us',
                        key: 'about-us'
                    },
                    {
                        label: 'Gallery',
                        key: 'gallery'
                    },
                    {
                        label: 'Contact',
                        key: 'contact',
                        children: [
                            {
                                label: '(661)-472-3080',
                                key: 'phone',
                                onClick: ()=>window.open("tel:+1-661-472-3080", '_blank')?.focus(),
                                icon: <AiFillPhone />
                            },
                        ]
                    },
                    {
                        label: 'Socials',
                        key: 'socials',
                        children: [
                            {
                                label: 'Yelp',
                                key: 'yelp',
                                onClick: ()=>window.open("https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield", '_blank')?.focus(),
                                icon: <BsYelp />
                            },
                        ]
                    },
                ]} />
            </MenuContainer>
        </Container>
    )
}

const Container = styled.nav`
    position: sticky;
    top: 0px;
    transition: background-color 1s;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    align-items: center;
    z-index: 10;
    background-color: rgba(255,255,255,1);
    box-shadow: 10px 10px 10px rgba(0,0,0,0.2);

    & .ant-menu-item-selected {
        color: rgba(0, 0, 0, 0.88) !important;
        font-weight: bold;
    }
    & .ant-menu-item-selected::after {
        border-bottom-color: rgba(0, 0, 0, 0.88) !important;
    }
`
const MenuContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`
const StyledDesktopMenu = styled(DesktopMenu)`
    width: calc(100% - 1px);
    background: none;
`
const CompanyName = styled.h3`
    user-select: none;
    cursor: pointer;
    margin: 0px;
    margin-left: 15px;
    margin-right: 15px;
    @media (max-width: 800px) {
        display: none;
    }
`