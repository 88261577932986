import { BrowserRouter, Routes as BrowserRoutes, Route } from "react-router-dom";
import { Layout } from "../components/layout/layout";
import { About } from "./about/About";
import { Gallery } from "./gallery/gallery";
import { Home } from "./home/home";

export const Routes = () => (
    <BrowserRouter>
        <Layout>
            <BrowserRoutes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/contact" element={<Home />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/socials" element={<Home />} />
            </BrowserRoutes>
        </Layout>
    </BrowserRouter>
)