import { styled } from 'styled-components';
import { BeforeAfter } from '../../components/before-after';
import BeforeAfter0 from './assets/before_after0.jpg';

import Before0 from "../../assets/gallery/before0.webp";
import Before1 from "../../assets/gallery/before1.webp";
import Before2 from "../../assets/gallery/before2.webp";
import Before3 from "../../assets/gallery/before3.webp";

import After0 from "../../assets/gallery/after0.webp";
import After1 from "../../assets/gallery/after1.webp";
import After2 from "../../assets/gallery/after2.webp";
import After3 from "../../assets/gallery/after3.webp";

import img0 from "../../assets/gallery/img0.webp";
import img1 from "../../assets/gallery/img1.webp";
import img3 from "../../assets/gallery/img3.webp";
import img4 from "../../assets/gallery/img4.webp";
import img5 from "../../assets/gallery/img5.webp";
import img6 from "../../assets/gallery/img6.webp";
import img7 from "../../assets/gallery/img7.webp";
import img8 from "../../assets/gallery/img8.webp";
import img9 from "../../assets/gallery/img9.webp";
import img10 from "../../assets/gallery/img10.webp";
import { Image } from 'antd';

export const Gallery = () => {
    return (
        <Container>
            <BeforeAfter before={Before0} after={After0} />
            <BeforeAfter before={Before1} after={After1} />
            <BeforeAfter before={Before2} after={After2} />
            <BeforeAfter before={Before3} after={After3} />
            <Image src={img0} height="400px" />
            <Image src={img1} height="400px" />
            <Image src={img3} height="400px" />
            <Image src={img4} height="400px" />
            <Image src={img5} height="400px" />
            <Image src={img6} height="400px" />
            <Image src={img7} height="400px" />
            <Image src={img8} height="400px" />
            <Image src={img9} height="400px" />
            <Image src={img10} height="400px" />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    flex: 1;
    padding: 25px;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.05);
    gap: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`
const ItemContainer = styled.div`
    width: 100%;
`