type LandingArtProps = React.PropsWithChildren<{
    color?: string
    style?: React.CSSProperties
    flipHorizontally?: boolean
    flipVertically?: boolean
}>
export const ShortAboutAngle = (props: LandingArtProps) =>
<div style={props.style ? {...props.style, width: '100%'} : {width: '100%', height: '100px'}}>
    <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 100 100" style={{transform:`scale(${props.flipHorizontally ? '-1' : '1'},${props.flipVertically ? '-1' : '1'})`}}>
        <path d="M 0 100 L 100 100 L 100 0 S 0 0 0 100" fill={props.color ?? 'white'} />
    </svg>
</div>