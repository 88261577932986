import { Typography } from "antd"
import { styled } from "styled-components"
import { YelpReview } from "../../components/yelp-review/yelp-review"
import { LandingArt } from "./assets/landing-art"
import { yelpReviews } from "./yelp-reviews";
import LaundryRoom from "../../assets/backgrounds/laundry_room_compressed.webp";
import { ShortAboutAngle } from "./assets/short-about-angle";
import { useEffect, useState } from "react";

import Before0 from "../../assets/gallery/before0.webp";
import Before1 from "../../assets/gallery/before1.webp";
import Before2 from "../../assets/gallery/before2.webp";
import Before3 from "../../assets/gallery/before3.webp";

import After0 from "../../assets/gallery/after0.webp";
import After1 from "../../assets/gallery/after1.webp";
import After2 from "../../assets/gallery/after2.webp";
import After3 from "../../assets/gallery/after3.webp";
import { BeforeAfter } from "../../components/before-after"; 

type HomeProps = React.PropsWithChildren<{}>

export const Home = (props: HomeProps) => {
    const [scrollY, setScrollY] = useState<number>(0);
    const [opacityHeight, setOpacityHeight] = useState<number>(1);
    const [companyNameOpacity, setCompanyNameOpacity] = useState<number>(1);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
      
        return () => window.removeEventListener('scroll', handleScroll);
      },[]);
    useEffect(() => {
        setOpacityHeight(Math.floor(window.innerHeight*0.35))
    }, [window.innerHeight])
    const handleScroll = () => {
        setScrollY(window.scrollY);
      };
    useEffect(() => {
        setCompanyNameOpacity(1-Math.min(opacityHeight, scrollY)/opacityHeight)
    }, [scrollY, opacityHeight])

    return (
        <Container>
            <LandingCompanyInfo>
                <ImageDarkener />
                <NameContainer style={{display: companyNameOpacity === 0 ? 'none' : undefined}}>
                    <CompanyName style={{opacity: companyNameOpacity}}>Macvac Dryer Vent Cleaning</CompanyName>
                </NameContainer>
            </LandingCompanyInfo>
            <ShortAboutContainer>
                <ShortAboutAngle style={{position: 'absolute', top: '-5vh', height: '5vh'}} />
                <LandingArt width="20em" height="20em" />
                <AboutTextContainer>
                    <Typography.Text>Since 1994, Macvac has provided total system cleaning to prevent fire hazards, save energy, and extend the life of your dryer. Macvac even checks your washer drain line, flush it if necessary, replace your transition duct if damaged, and clean your washer and dryer, for no additional cost. We do a before and after airflow temperature test to ensure optimal operation. We provide a One year airflow warranty in writing. Satisfaction guaranteed!</Typography.Text>
                </AboutTextContainer>
                <ShortAboutAngle flipHorizontally flipVertically style={{position: 'absolute', bottom: '-4.9vh', height: '5vh'}} />
            </ShortAboutContainer>
            <GalleryContainer id="GALLERY">
                <BeforeAfter before={Before0} after={After0} />
                <BeforeAfter before={Before1} after={After1} />
                <BeforeAfter before={Before2} after={After2} />
                <BeforeAfter before={Before3} after={After3} />
            </GalleryContainer>
            <YelpContainer>
                <ShortAboutAngle color="#a02f24" style={{position: 'absolute', top: '-5vh', height: '5vh'}} />
                <YelpTitle>We have 5 stars on Yelp!</YelpTitle>
                <YelpReviewContainer>{
                    yelpReviews.map((review, i) => <YelpReview key={i} {...review}></YelpReview>)
                }</YelpReviewContainer>
            </YelpContainer>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    flex: 1;
`
const YelpContainer = styled.section`
    display: flex;
    flex-direction: column;
    background: #a02f24;
    z-index: 9;
    position: relative;
`
const YelpReviewContainer = styled.div`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`
const YelpTitle = styled(Typography)`
    text-align: center;
    color: white;
    font-size: 200%;
`
const LandingCompanyInfo = styled.section`
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-image: url(${LaundryRoom});
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: none;
`
const NameContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 45px);
`
const ImageDarkener = styled.div`
    background-color: rgba(10,0,0,0.6);
    position: absolute;
    width: 100%;
    height: 100%;
`
const CompanyName = styled.h1`
    font-size: 2.5em;
    color: white;
    text-align: center;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
`
const ServingBakersfield = styled(Typography)`
    color: white;
    text-align: center;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
`
const ShortAboutContainer = styled.section`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 10px 10px 100px rgba(0,0,0,0.2);
`
const AboutTextContainer = styled.div`
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px;
`
const GalleryContainer = styled.section`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 100px 10px;
    box-sizing: border-box;
    gap: 25px;
    justify-content: center;
    background-color: rgba(0,0,0,0.05);
`