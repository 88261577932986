interface YelpReview {
    yelpReviewLink: string,
    name: string,
    review: string,
    location: string
}
export const yelpReviews: YelpReview[] = [
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=KdihIdvCgDKKN_3Rpnu_TQ&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "Randa G.",
        review: "Quick, efficient, and effective. Highly recommend. We hadn't had our dryer vent cleaned in 22 years and were at risk of starting a fire.",
        location: "Bakersfield, CA"
    },
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=tffnnT-NikzLovbquPfMYg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "Janet H.",
        review: "Been using MacVac for over 10 years.  Professional service.  My washer and dryer alway look great after they leave.  They also clean behind my appliances. Will continue to use them.",
        location: "Los Angeles, CA"
    },
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=LO6KblH3bS-80nOLfqOv2g&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "Diane P.",
        review: "Great response! Great cleaning of our dryer vent! Great person ( which was Jordy) to explain things to us!",
        location: "Bakersfield, CA"
    },
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=iuUkI4mNAq1wAHbakf9ygA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "Philip B.",
        review: "Showed up on time and did a great job cleaning the vent. He explained everything he was doing and very polite. I will use them again next year.",
        location: "Bakersfield, CA"
    },
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=CpOBwp40-DzouCB0dPL5og&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "Barry S.",
        review: "Prompt, great price, and very quick service. Definitely will be calling them again for any dryer vent cleaning needs.",
        location: "Bakersfield, CA"
    },
    {
        yelpReviewLink: "https://www.yelp.com/biz/macvac-dryer-vent-cleaning-bakersfield?hrid=jR96RoneGC9GyihqTlVCAA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        name: "David G.",
        review: "Quick response time. Tech showed up an hour early and got the job done. Will absolutely be using for all our future dryer vent cleanings.",
        location: "Bakersfield, CA"
    },
]