import { Card, Typography } from "antd"
import { AiFillStar } from "react-icons/ai"
import { BsYelp } from "react-icons/bs"
import { IconContext } from "react-icons/lib"
import { styled } from "styled-components"

type YelpReview = React.PropsWithChildren<{
    yelpReviewLink: string,
    name: string,
    review: string,
    location: string
}>

export const YelpReview = (props: YelpReview) => {
    return (
        <Container>
            <IconContext.Provider value={{color: "rgba(251,67,60,1)"}}>
                <HeaderContainer>
                    <BsYelp style={{fontSize: 30, color: 'rgba(255,26,26,1)'}} />
                    <UserDetailsContainer>
                        <UserName>{props.name}</UserName>
                        <UserLocation>{props.location}</UserLocation>
                    </UserDetailsContainer>
                    <StarsContainer>
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                    </StarsContainer>
                </HeaderContainer>
                <ReviewContainer>
                    
                    <Typography.Text>{props.review}</Typography.Text>
                </ReviewContainer>
                <CoverAnchor href={props.yelpReviewLink} target="_blank">View Yelp Page</CoverAnchor>
            </IconContext.Provider>
        </Container>
    )
}

const Container = styled(Card)`
    max-width: 100%;
    width: 500px;
    box-shadow: 10px 10px 10px rgb(0,0,0,0.1);
    cursor: pointer;
    position: relative;
    z-index: 3;
`
const UserDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const ReviewContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const UserName = styled.label`
    
`
const UserLocation = styled.label`
    
`
const StarsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`
const CoverAnchor = styled.a`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    color: rgba(0,0,0,0);
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all 0.5s;
    &:hover {
        background-color: rgba(0,0,0,0.4);
        color: rgba(255,255,255,1)
    }
`
const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`