import { Card, Image, Typography } from "antd"
import { styled } from "styled-components"

type BeforeAfterProps = React.PropsWithChildren<{
    before: any
    after: any
    fitContainer?: boolean
}>

export const BeforeAfter = (props: BeforeAfterProps) => {
    return (
        <CardContainer bordered bodyStyle={{width: '100%', height: '100%'}} style={{maxWidth: props.fitContainer ? '100%' : '600px'}}>
            <Content>
                <TimeContainer>
                    <TimeTitle>Before</TimeTitle>
                    <ImageContainer>
                        <TimeImage alt="Before Image" width="100%" height="100%" src={props.before} />
                    </ImageContainer>
                </TimeContainer>
                <TimeContainer>
                    <TimeTitle>After</TimeTitle>
                    <ImageContainer>
                        <TimeImage alt="After Image" width="100%" height="100%" src={props.after} />
                    </ImageContainer>
                </TimeContainer>
            </Content>
        </CardContainer>
    )
}

const CardContainer = styled(Card)`
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
`
const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
`
const TimeContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 200px;
    max-width: 100%;
    align-items: center;
`
const TimeTitle = styled(Typography)`
    text-align: center;
    font-size: 150%;
`
const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    max-width: 100%;
`
const TimeImage = styled(Image)`
    object-fit: cover;
    width: 100%;
    height: 100%;
`