import { Button, Typography } from "antd"
import { styled } from "styled-components"

type FooterProps = React.PropsWithChildren<{}>

export const Footer = (props: FooterProps) => {
    return (
        <FooterContainer>
            <TopContainer>
            <FooterTitle>Macvac Dryer Vent Cleaning - Bakersfield</FooterTitle>
                <FooterMenu>
                    <InfoText>Call or Text: 661-472-3080</InfoText>
                </FooterMenu>
            </TopContainer>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    width: 100%;
    background-color: rgb(30,30,30);
`
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
`
const FooterMenu = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;

`
const InfoText = styled(Typography)`
    color: white;
    font-size: 1rem;
    text-align: center;
    text-wrap: nowrap;
`
const FooterMenuButton = styled(Button)`
    color: white;
`
const MenuButton = (props: {onClick: ()=>void, label: string}) => <FooterMenuButton type="text" onClick={props.onClick}>{props.label}</FooterMenuButton>
const FooterTitle = styled(Typography)`
    color: white;
    font-size: 1.2rem;
    text-align: center;
    flex: 1;
    text-wrap: nowrap;
`