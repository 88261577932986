import { styled } from "styled-components"

type ContentProps = React.PropsWithChildren<{}>

export const Content = (props: ContentProps) => {
    return (
        <Container>
            {props.children}
        </Container>
    )
}
const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`