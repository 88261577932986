import styled from "styled-components"
import { Content } from "./content"
import { Footer } from "./footer"
import { Navbar } from "./navbar"

type LayoutProps = React.PropsWithChildren<{}>

export const Layout = (props: LayoutProps) => (
    <Container>
        <Navbar />
        <Content>
            {props.children}
        </Content>
        <Footer />
    </Container>
)

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh + 70px)
`