import { Typography } from "antd"
import styled from "styled-components"

type AboutProps = React.PropsWithChildren<{}>

export const About = (props: AboutProps) => {
    return (
        <Container>
            <AboutSection>
                <AboutSectionTitle>History</AboutSectionTitle>
                <AboutSectionText>Established in 1994 in San Antonio Texas, in business for 10 years and moved to Bakersfield. Been doing business in Bakersfield since 2004. Featured on channel 17 news as one of the best services in Bakersfield. Veteran owned.</AboutSectionText>
            </AboutSection>
            <AboutSection>
                <AboutSectionTitle>About the owner</AboutSectionTitle>
                <AboutSectionText>Leon McDuffy is military retired with over 26 years of service in the military. Perfesionally trained in maintenance, engineering, and fire safety.</AboutSectionText>
            </AboutSection>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    flex: 1;
    background-color: rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`
const AboutSection = styled.section`
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;
`
const AboutSectionTitle = styled.h2`
    text-align: center;
`
const AboutSectionText = styled(Typography)`
    text-align: center;
`